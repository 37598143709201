import request1 from '../util/loginrequest'

// 历史记录列表
// export const Historicalrecordlist = (params) => {
//   return request1({
//     url: '/api/demo/subscribe/history',
//     method: 'post',
//     data: {
//       user_id: params.user_id || ''
//     }
//   })
// }
export const Historicalrecordlist = (params) => {
  return request1({
    url: '/api/demo/subscribe/history_new',
    method: 'post',
    data: {
      user_id: params.user_id || ''
    }
  })
}
// 添加申报指南历史记录
export const Addhistoricalrecord = (params) => {
  return request1({
    url: 'api/demo/ProjectGov/projectgov_history_add',
    method: 'post',
    data: {
      title_id: params.title_id || '',
      user_id: params.user_id || ''
      // type: params.type || ''
    }
  })
}
// 添加政采招标历史记录
export const Addhistoricalrecordzc = (params) => {
  return request1({
    url: 'api/demo/Tender/tender_history_add',
    method: 'post',
    data: {
      title_id: params.title_id || '',
      user_id: params.user_id || ''
      // type: params.type || ''
    }
  })
}
// 添加科研项目历史记录
export const Addhistoricalrecordky = (params) => {
  return request1({
    url: 'api/demo/Project/project_history_add',
    method: 'post',
    data: {
      title_id: params.title_id || '',
      user_id: params.user_id || ''
      // type: params.type || ''
    }
  })
}
// 添加深度文章历史记录
export const Addhistoricalrecordwz = (params) => {
  return request1({
    url: 'api/demo/Articles/articles_history_add',
    method: 'post',
    data: {
      title_id: params.title_id || '',
      user_id: params.user_id || ''
      // type: params.type || ''
    }
  })
}

